<template>
<!--用户协议-->
  <div class="userAgreement">
    <h3>用户协议</h3>
    <span style="color: #909399;letter-spacing: 3px;">
      云ICU是一个全球医疗交流平台,现已加入重症
4S店功能,更好的确保用户可以随时随地与专家面对
面交流。本次更新开通了重症外科和职称考试版块!
介绍 云ICU是面向全球的重症医学交流平台，汇聚
全球顶尖专家，提供重症医学咨询、在线教育、在
线会议、学术讨论等服务，目前平台已有超过1500
个专题视频课件。优化了版面和部分功能的使用，并
解决了iPhone X和iPhone XS系
    </span>
    <div  style="color: #909399;margin-top: 9%;letter-spacing: 3px;">
      <div>1.即时性，无延迟，适应复杂的网络环境。</div>
      <div>2.实现全球互联互通，ICU交流平台。</div>
      <div>3.服务ICU，惠民医疗，医、教、研、商同步发展。</div>
      <div>4.操作简单明了</div>
      <div>5.PC、PAD、手机均可接入，可无限扩展各种医疗
        系统。</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userAgreement",
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
.userAgreement{
  margin: 0 auto;
  padding: 4%;
}
</style>
